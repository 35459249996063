<template>
  <PageContent>
    <SubHeading>Articles</SubHeading>
    <AdminBreadCrumb />
    <ArticleList :articles="articles" :showDelete="true" @clicked="articleClicked" @del="delArticle"/>
    <div class="new">
      <h3>Create new Article</h3>
      <input type="text" placeholder="Article Name" v-model="articleName">
      <button @click="create" >Create</button>
    </div>
  </PageContent>
</template>


<script>
import {ref, onMounted} from "vue";
import router from '@/router'

import PageContent from '@/components/PageContent'
import SubHeading from '@/components/SubHeading'
import ArticleList from '@/components/ArticleList'
import AdminBreadCrumb from '@/components/Admin/AdminBreadCrumb'

import ArticleService from '@/services/ArticleService.js'

export default {
  setup(){
    const articles = ref([]);
    const articleName = ref('');

    onMounted(async () => {
      refreshList();
    });

    const refreshList = async () => articles.value = await getArticles();
    const create = async () => {
      if (articles.value.includes(articleName.value)) {
        alert('Cannot Create new Article, Article Already Exists!!!');
        return;
      }
      await ArticleService.saveArticle(articleName.value.toLowerCase(), '');
      articles.value = await ArticleService.getArticles();
    }

    const getArticles = async () => (await ArticleService.getArticles()).sort(); 
    const articleClicked = (name) => {
      router.push(`articles/${name}`);
    }
    const delArticle = async (name) => {
      const ok = confirm(`Are you sure you want to delete "${name}"`);
      if (ok) {
        await ArticleService.deleteArticle(name);
        await refreshList();
      }
    }
    return {articles, articleName, create, articleClicked, delArticle}
  },
  components: {
    PageContent,
    SubHeading,
    ArticleList,
    AdminBreadCrumb
  }
};
</script>


